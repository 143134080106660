export default function ({ route, redirect }) {
  const routePath = route.path.toLowerCase();

  if (routePath.includes("cloudguard/cdn")) {
    return redirect("/cdn");
  }

  if (routePath.includes("cloudguard/dns")) {
    return redirect("/dns");
  }

  if (routePath.includes("cloudguard/cloud-security")) {
    return redirect("/cloud-security");
  }

  if (routePath.includes("cloudguard")) {
    return redirect("/cdn");
  }
}
