
import TheHeader from "../components/partials/Header/TheHeader.vue";
import TheSeoHiddenNavbar from "../components/partials/Header/TheSeoHiddenNavbar.vue";

export default {
  name: "DefaultLayout",
  // head() {
  //   return {
  //     link: [
  //       // Preload your web fonts here
  //       {
  //         rel: 'preload',
  //         as: 'font',
  //         type: 'font/woff2',
  //         href: '/seo-content/PelakFA-Medium.woff2',
  //         crossorigin: 'anonymous',
  //       },
  //       // {
  //       //   rel: 'preload',
  //       //   as: 'font',
  //       //   type: 'font/woff2',
  //       //   href: 'https://fonts.googleapis.com/css?family=Roboto&display=swap',
  //       //   crossorigin: 'anonymous',
  //       // },

  //       // You can add more preload links for other fonts if needed
  //     ],
  //   };
  // },
  components: { TheHeader, TheSeoHiddenNavbar },
  data() {
    return {
      drawer: false,
      user: "",
    };
  },

  async fetch() {
    console.log("fetch call", this.$cookies.get("token"));
    // if (this.$cookies.get("token")) {
    //   console.log("get user info in layout", this.$cookies.get("token"));

    //   //set token to axios header
    //   this.$axios.setToken(this.$cookies.get("token"), "bearer");

    //   {
    //     try {
    //       let rs = await this.$axios.$get("api/v1/users/");
    //       if (rs.success) {
    //         this.$store.commit("login");
    //         this.user = rs.data.first_name + rs.data.last_name;

    //         let { mobile, email, first_name, last_name } = rs.data;

    //         // save user info to store

    //         this.$store.commit("add", {
    //           mobile: mobile,
    //           email: email,
    //           first_name: first_name,
    //           last_name: last_name,
    //           newUser: false,
    //         });
    //       } else {
    //         this.$store.commit("remove");
    //         this.$store.commit("logout");
    //       }
    //     } catch (error) {
    //       console.error("error in fetch", error);
    //       // this.$store.commit("remove");
    //       // this.$store.commit("logout");
    //     }
    //   }
    // } else {
    //   // this.$store.commit("remove");
    //   // this.$store.commit("logout");
    // }
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
  mounted() {
    this.$vuetify.rtl = true;
    // this.$fetch();
  },
};
